import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResultService } from '../../core/services/search-result/search-result.service';
import { SearchResultModel } from '../../core/models/searchResult/search.result.model';
import { CompanyModel } from '../../core/models/company/company.model';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { BannerAdModel } from './../../core/models/bannerAd/banner.ad.model';
import { AutoCompleteModel } from '../../core/models/common/auto.complete.model';
import {} from 'google.maps';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-search-list-view',
	templateUrl: './search.list.view.component.html',
	styleUrls: ['./search.list.view.component.scss'],
})
export class SearchListViewComponent implements OnInit {
	@Input() searchTerm: string = '';
	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();

	directory: string = '';
	location: string = '';
	apiKey: string = 'AIzaSyC-gkCR01R3SN8ofyKZO-ZQpPKop2t8vA8';
	map: google.maps.Map;
	infowindow: google.maps.InfoWindow;
	results: SearchResultModel[] = [];
	fullCompanies: CompanyModel[] = [];
	companies: CompanyModel[] = [];
	selectedCompany: CompanyModel;
	quickLinks: QuickLinkModel[] = [];
	bannerAds: BannerAdModel[] = [];
	impactAds: any[] = [];
	categories: any[] = [];
	headingDescription: any[] = [];
	autoList: AutoCompleteModel[] = [];
	sortOption: string = '';

	currentPage: number = 1;
	itemsPerPage: number = 10;

	constructor(
		private searchResultService: SearchResultService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private route: ActivatedRoute,
		private meta: Meta,
		private titleService: Title
	) {}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((queryParams) => {
			const querySearchTerm = queryParams.get('searchTerm');
			this.directory = queryParams.get('directory');
			this.location = queryParams.get('location');
			if (querySearchTerm) {
				this.searchTerm = querySearchTerm;
			}
			this.setSeoTags();
		});

		this.getSearchResults();
	}

	// Set dynamic SEO meta tags and title
	setSeoTags(): void {
		const title = `Search Results for "${this.searchTerm}" in ${this.directory}`;
		this.titleService.setTitle(title);

		const canonicalUrl = `https://www.arvigpages.com/#/search-list-view?searchTerm=${encodeURIComponent(this.searchTerm)}&directory=${encodeURIComponent(this.directory)}&location=${encodeURIComponent(this.location)}`;
		this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });

		this.meta.updateTag({
			name: 'description',
			content: `Find the best companies related to "${this.searchTerm}" in ${this.directory} located in ${this.location}`,
		});
		this.meta.updateTag({
			name: 'keywords',
			content: `${this.searchTerm}, ${this.directory}, companies, businesses in ${this.location}`,
		});
		this.meta.updateTag({ name: 'robots', content: 'index, follow' });

		// Additional Open Graph tags for social media
		this.meta.updateTag({ property: 'og:title', content: title });
		this.meta.updateTag({
			property: 'og:description',
			content: `Explore top results for "${this.searchTerm}" in ${this.directory}`,
		});
		if (typeof window !== 'undefined') {
			this.meta.updateTag({ property: 'og:url', content: window.location.href });
		}
		this.meta.updateTag({ property: 'og:image', content: 'URL_to_preview_image' });
	}

	setStructuredData() {
		const schemaData = {
			'@context': 'https://schema.org',
			'@type': 'SearchResultsPage',
			mainEntity: this.companies.map((result) => ({
				'@type': 'Organization',
				name: result.company,
				url: this.getFullUrl(result.website),
				logo: result.companyLogoImage,
				description: result.companyDescription,
				address: {
					'@type': 'PostalAddress',
					streetAddress: result.streetAddress,
					addressLocality: result.city,
					addressRegion: result.state,
					postalCode: result.zip,
				},
				telephone: result.phone,
				geo: {
					'@type': 'GeoCoordinates',
					latitude: result.latitude,
					longitude: result.longitude,
				},
			})),
		};
		if (typeof document !== 'undefined') {
			const script = document.createElement('script');
			script.type = 'application/ld+json';
			script.text = JSON.stringify(schemaData);
			document.head.appendChild(script);
		}
	}

	async getSearchResults() {
		this.spinner.show();
		try {
			if (this.location === 'Everywhere') {
				this.results = await this.searchResultService.getSearchResults(this.directory, '', this.searchTerm);
			} else {
				this.results = await this.searchResultService.getSearchResults(this.directory, this.location, this.searchTerm);
			}

			let companiesResultsFromServer = this.results[0].companySearchResults;

			this.fullCompanies = companiesResultsFromServer.map((element) => ({
				micrositeID: element.micrositeID,
				companyID: element.companyID,
				companyIssueID: element.companyIssueID,
				companySortOrder: element.companySortOrder,
				company: element.company,
				companyDescription: element.companyDescription,
				sold: element.sold,
				priority: element.priority,
				iypPackageScore: element.iypPackageScore,
				website: element.website,
				companyLogoImage: 'http://' + element.companyLogoImage,
				fullAddress: element.fullAddress,
				streetAddress: element.streetAddress,
				city: element.city,
				state: element.state,
				cityState: element.cityState,
				zip: element.zip,
				phone: element.phone,
				longitude: element.longitude,
				latitude: element.latitude,
				headings: element.headings.map((heading: any) => ({
					headingID: heading.headingID,
					headingName: heading.headingName,
				})),
				isPreferred: element.isPreferred,
				yellowHighlight: element.yellowHighlight,
			}));

			// Apply sorting and pagination
			this.sortCompanies(this.sortOption);

			this.quickLinks = this.results[0].quickLinks.map((element) => ({
				groupName: element.groupName,
				title: element.title,
				url: this.getFullUrl(element.url),
			}));

			this.headingDescription = this.results[0].headingDescription.map((element) => ({
				title: element.title,
				description: element.description,
			}));

			this.bannerAds = this.results[0].bannerAds.map((element) => ({
				bannerAdImage: 'http://' + element.bannerAdImage,
				adClickURL: 'http://' + element.adClickURL,
			}));

			this.impactAds = this.results[0].impactAds.map((element) => ({
				adID: element.adId,
				impactAdImage: 'http://' + element.impactAdImage,
				adClickURL: 'http://' + element.adClickURL,
				sortOrder: element.sortOrder,
			}));

			this.categories = this.results[0].relatedCategories.map((element) => ({
				title: element.title,
				searchTerm: element.searchTerm,
			}));
			this.setStructuredData();
		} catch (error) {
			console.error('Error fetching search results:', error);
		} finally {
			this.spinner.hide();
		}
	}

	// Sorting method for companies
	sortCompanies(option: string) {
		this.sortOption = option;
		let companiesToSort = [...this.fullCompanies];

		switch (this.sortOption) {
			case 'bestMatch':
				companiesToSort.sort((a, b) => a.companySortOrder - b.companySortOrder);
				break;
			case 'aToZ':
				companiesToSort.sort((a, b) => a.company.localeCompare(b.company));
				break;
			case 'distance':
				this.getUserLocation()
					.then((userLocation) => {
						if (!userLocation.lat || !userLocation.lng) {
							console.error('Invalid user location:', userLocation);
							return;
						}

						companiesToSort.forEach((company) => {
							if (!isNaN(company.latitude) && !isNaN(company.longitude)) {
								const companyLocation = new google.maps.LatLng(company.latitude, company.longitude);
								const userLatLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
								company['distance'] = google.maps.geometry.spherical.computeDistanceBetween(
									userLatLng,
									companyLocation
								);
							} else {
								console.error('Invalid company location:', company);
							}
						});

						// Sort by distance and then paginate
						companiesToSort.sort((a, b) => (a['distance'] || 0) - (b['distance'] || 0));
						this.companies = companiesToSort;
						this.paginateCompanies();
					})
					.catch((error) => {
						console.error('Error fetching user location:', error);
					});
				return;
			default:
				break;
		}
		// Update the list of companies
		this.companies = companiesToSort;
		this.paginateCompanies();
	}

	paginateCompanies() {
		console.log('Paginating companies for page:', this.currentPage);
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		// Paginate the sorted list
		this.companies = this.companies.slice(startIndex, endIndex);
	}

	onSelectCompany(company: CompanyModel) {
		this.selectedCompany = company;
	}

	onSearchTermChanged(term: string) {
		this.searchTerm = term;
		this.currentPage = 1;
		this.spinner.show();
		setTimeout(() => {
			this.getSearchResults();
		}, 1000);
		this.autoList = [];
	}

	onLocationChanged(location: string) {
		this.location = location;
		this.currentPage = 1;
		this.getSearchResults();
	}

	stopPropagation(event: Event): void {
		event.stopPropagation();
	}

	changePage(page: number) {
		this.currentPage = page;
		this.getSearchResults();
		this.paginateCompanies(); // Ensure pagination is applied
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}

	navigateToMicroSite(company: CompanyModel) {
		this.selectedCompany = company;
		this.router.navigate(['microsite', this.selectedCompany.micrositeID], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
		});
	}

	navigateToMapView() {
		this.router.navigate(['map-list-view'], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
		});
	}

	navigateToDirectoryHome() {
		if (this.directory) {
			this.router.navigate(['home'], {
				queryParams: { directory: this.directory },
			});
		}
	}

	setHeadingSearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.router
			.navigate(['search-list-view'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
			})
			.then(() => {
				this.getSearchResults();
			});
	}

	setCategorySearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.router
			.navigate(['search-list-view'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
			})
			.then(() => {
				this.getSearchResults();
			});
	}

	getFullUrl(url: string): string {
		if (url.startsWith('/')) {
			if (typeof window !== 'undefined') {
				return 'http://' + window.location.host + url;
			}
		} else {
			return url;
		}
	}

	getUserLocation(): Promise<{ lat: number; lng: number }> {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
					},
					(error) => {
						reject(error);
					}
				);
			} else {
				reject('Geolocation is not supported by this browser.');
			}
		});
	}
}
