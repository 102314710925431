import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectoryHomeModel } from 'src/app/components/core/models/directoryHome/directory.home.model';
import { LocationModel } from 'src/app/components/core/models/location/location.model';
import { DirectoryHomeService } from 'src/app/components/core/services/directory-home/directory-home.service';
import { SearchListViewComponent } from './../../search.list.view.component';
import { AutoCompleteService } from 'src/app/components/core/services/auto-complete/auto-complete.service';
import { AutoCompleteModel } from 'src/app/components/core/models/common/auto.complete.model';

@Component({
	selector: 'app-search-list-view-banner',
	templateUrl: './search-list-view-banner.component.html',
	styleUrls: ['./search-list-view-banner.component.scss'],
})
export class SearchListViewBannerComponent {
	@Input() directory: string = '';
	directoryHomeData: DirectoryHomeModel = null;
	@Input() autoList: AutoCompleteModel[] = [];
	@Input() searchTerm: string = '';
	@Input() selectedLocation: string = '';
	locations: LocationModel[] = [];
	options: any[] = [];
	config = {
		displayKey: 'name',
		search: true,
	};

	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();
	@Output() locationChanged: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private directoryHomeService: DirectoryHomeService,
		private autoCompleteService: AutoCompleteService,
		private router: Router,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService
	) {}

	ngOnInit(): void {
		this.getHomeBannerData();
		this.route.queryParamMap.subscribe((queryParams) => {
			this.searchTerm = queryParams.get('searchTerm') || '';
			this.selectedLocation = queryParams.get('location') || '';
		});
	}

	navigateSearchList() {
		if (!this.selectedLocation) {
			this.selectedLocation = 'Everywhere';
		}
		this.router.navigate(['search-list-view'], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
		});
		this.searchTermSelected.emit(this.searchTerm);
	}

	async getHomeBannerData() {
		try {
			this.spinner.show();
			this.directoryHomeData = await this.directoryHomeService.getDirectoryHomeResults(this.directory);
			if (this.directoryHomeData) {
				this.locations = this.directoryHomeData[0].locations.map((element) => ({
					name: element.name,
					isDefault: element.isDefault,
				}));

				const defaultLocation = this.locations.find((location) => location.isDefault);

				if (defaultLocation && !this.selectedLocation) {
					this.selectedLocation = defaultLocation.name;
					this.locationChanged.emit(this.selectedLocation);
				}

				this.options = this.locations;
			} else {
				console.error('Error: Directory home data is null or undefined');
			}
		} catch (error) {
			console.error('Error fetching category data:', error);
			this.spinner.hide();
		}
	}

	async getAutoSearchList() {
		try {
			const response = await this.autoCompleteService.getAutoCompleteResults(this.directory, this.searchTerm);
			this.autoList = response.map((element) => ({
				searchTermMatch: element.searchTermMatch,
			}));
		} catch (error) {
			console.error('Error fetching auto-complete data:', error);
		} finally {
			this.spinner.hide();
		}
	}

	onSearchTermInput(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		this.searchTerm = inputElement.value;
		if (this.searchTerm.length >= 2) {
			this.getAutoSearchList();
		} else {
			this.autoList = [];
		}
	}

	onLocationChanged(event: { value: { name: string } }) {
		this.selectedLocation = event.value.name;
		this.locationChanged.emit(this.selectedLocation);
	}

	selectAutoCompleteItem(item: AutoCompleteModel) {
		this.searchTerm = item.searchTermMatch;
		this.searchTermSelected.emit(this.searchTerm);
		this.autoList = [];
	}
}
