import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CategoryService } from './../../core/services/category/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryModel } from '../../core/models/category/category.model';
import { Meta, Title } from '@angular/platform-browser'; // Import Meta and Title

@Component({
	selector: 'app-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
	categories: CategoryModel[] = [];
	searchTerm: string = '';
	directory: string = '';
	response: any[] = [];
	selectedLocation: string = 'Everywhere';

	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private categoryService: CategoryService,
		private route: ActivatedRoute,
		private router: Router,
		private meta: Meta, // Inject Meta
		private title: Title // Inject Title
	) {}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((queryParams) => {
			this.directory = queryParams.get('directory');
			this.setSEOData(); // Set SEO data when the directory changes
		});
		this.getCategories();
	}

	async getCategories() {
		try {
			this.response = await this.categoryService.getAllCategories(this.directory);
			console.log(this.response);
			let categoriesFromServer = this.response[0].categories;

			categoriesFromServer.forEach((element) => {
				this.categories.push({
					categoryName: element.categoryName,
					categoryTags: element.categoryTags.map((category) => ({
						title: category.title,
						searchTerm: category.searchTerm,
						categoryIcon: 'http://' + category.categoryIcon,
					})),
				});
			});
		} catch (error) {
			console.error('Error fetching categories: ', error);
		}
	}

	navigateSearchList() {
		if (this.searchTerm === 'More Categories') {
			this.router.navigate(['/categories'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
			});
		} else {
			this.router.navigate(['search-list-view'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
			});
		}
	}

	setCategorySearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.navigateSearchList();
	}

	getCategoriesByCategory(categoryName: string): CategoryModel[] {
		return this.categories.filter((category) => category.categoryName === categoryName);
	}

	// Method to set SEO data
	private setSEOData() {
		this.title.setTitle(`Categories - Explore ${this.directory} | Arvig Pages`);
		this.meta.addTags([
			{
				name: 'description',
				content: `Explore various categories in ${this.directory}. Find services and businesses that match your needs.`,
			},
			{ name: 'keywords', content: `${this.directory}, categories, local services, business listings` },
		]);
	}
}
