import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResultModel } from '../../core/models/searchResult/search.result.model';
import { CompanyModel } from '../../core/models/company/company.model';
import { SearchResultService } from '../../core/services/search-result/search-result.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-map-list-view',
	templateUrl: './map.list.view.component.html',
	styleUrls: ['./map.list.view.component.scss'],
})
export class MapListViewComponent implements OnInit {
	directory: string = '';
	searchTerm: string = '';
	location: string = '';
	results: SearchResultModel[] = [];
	fullCompanies: CompanyModel[] = [];
	companies: CompanyModel[] = [];
	selectedCompany: CompanyModel;
	apiKey: string = 'AIzaSyC-gkCR01R3SN8ofyKZO-ZQpPKop2t8vA8';
	markers: google.maps.Marker[] = [];
	map: google.maps.Map;
	infowindow: google.maps.InfoWindow;
	quickLinks: QuickLinkModel[] = [];
	sortOption: string = '';

	currentPage: number = 1;
	itemsPerPage: number = 10;

	constructor(
		private searchResultService: SearchResultService,
		private spinner: NgxSpinnerService,
		private router: Router,
		private route: ActivatedRoute,
		private meta: Meta, // Inject Meta service
		private titleService: Title // Inject Title service
	) {}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((queryParams) => {
			const querySearchTerm = queryParams.get('searchTerm');
			this.directory = queryParams.get('directory');
			this.location = queryParams.get('location');

			if (querySearchTerm) {
				this.searchTerm = querySearchTerm;
			}
			this.setMetaTags();
			this.getSearchResults();
			this.loadGoogleMapsScript(() => {
				this.getSearchResults();
			});
		});
	}

	setMetaTags() {
		const title = `Map View - ${this.searchTerm || 'Search Results'}`;
		const description = `Find the best companies in ${this.location || 'your area'} related to ${this.searchTerm || 'your search term'}.`;
		const canonicalUrl = `https://www.arvigpages.com/#/search-list-view?searchTerm=${encodeURIComponent(this.searchTerm)}&directory=${encodeURIComponent(this.directory)}&location=${encodeURIComponent(this.location)}`;

		this.titleService.setTitle(title);
		this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });
		this.meta.updateTag({ name: 'description', content: description });
	}

	loadGoogleMapsScript(callback: () => void) {
		if (typeof google !== 'undefined' && google.maps) {
			callback();
		} else {
			if (typeof document !== 'undefined') {
				const script = document.createElement('script');
				script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=initMap&libraries=geometry`;
				script.async = true;
				script.defer = true;
				if (typeof window !== 'undefined') {
					window['initMap'] = () => {
						callback();
					};
				}
				document.head.appendChild(script);
			}
		}
	}

	async getSearchResults() {
		this.spinner.show();
		try {
			if (this.location === 'Everywhere') {
				this.results = await this.searchResultService.getSearchResults(this.directory, '', this.searchTerm);
			} else {
				this.results = await this.searchResultService.getSearchResults(this.directory, this.location, this.searchTerm);
			}
			const companiesResultsFromServer = this.results[0].companySearchResults;
			this.fullCompanies = [];

			this.markers.forEach((marker) => marker.setMap(null));
			this.markers = [];

			companiesResultsFromServer.forEach((element) => {
				const company: CompanyModel = {
					micrositeID: element.micrositeID,
					companyID: element.companyID,
					companyIssueID: element.companyIssueID,
					companySortOrder: element.companySortOrder,
					company: element.company,
					companyDescription: element.companyDescription,
					sold: element.sold,
					priority: element.priority,
					iypPackageScore: element.iypPackageScore,
					website: element.website,
					companyLogoImage: 'http://' + element.companyLogoImage,
					fullAddress: element.fullAddress,
					streetAddress: element.streetAddress,
					city: element.city,
					state: element.state,
					cityState: element.cityState,
					zip: element.zip,
					phone: element.phone,
					longitude: element.longitude,
					latitude: element.latitude,
					headings: element.headings,
					isPreferred: element.isPreferred,
					yellowHighlight: element.yellowHighlight,
				};

				this.fullCompanies.push(company);

				this.sortCompanies(this.sortOption);

				const marker = new google.maps.Marker({
					position: new google.maps.LatLng(company.latitude, company.longitude),
					map: this.map,
					title: company.company,
					icon: 'https://maps.google.com/mapfiles/ms/micons/red-dot.png',
				});

				marker.addListener('click', () => {
					this.infowindow.setContent(company.company);
					this.infowindow.open(this.map, marker);
				});

				this.markers.push(marker);
			});

			if (this.fullCompanies.length > 0) {
				this.selectedCompany = this.fullCompanies[0];
				this.initMap();
			}

			this.quickLinks = [];
			const quickLinksFromServer = this.results[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});
		} catch (error) {
			console.error('Error fetching search results', error);
		} finally {
			this.spinner.hide();
		}
	}

	async initMap() {
		if (!this.selectedCompany.latitude || !this.selectedCompany.longitude) {
			console.error('Invalid company or coordinates:', this.selectedCompany);
			return;
		}

		const mapOptions: google.maps.MapOptions = {
			zoom: 5,
			center: new google.maps.LatLng(this.selectedCompany.latitude, this.selectedCompany.longitude),
		};

		this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
		this.infowindow = new google.maps.InfoWindow();

		// Update markers only for current page companies
		this.updateMarkers();

		if (this.companies.length > 0) {
			this.map.setCenter(new google.maps.LatLng(this.companies[0].latitude, this.companies[0].longitude));
			this.map.setZoom(7);
		}
	}

	onSelectCompany(company: CompanyModel) {
		this.selectedCompany = company;
		this.map.setCenter(new google.maps.LatLng(company.latitude, company.longitude));
		this.map.setZoom(12);

		this.markers.forEach((marker) => {
			marker.setIcon('https://maps.google.com/mapfiles/ms/micons/red-dot.png');
		});

		const selectedMarker = this.markers.find((marker) => marker.getTitle() === this.selectedCompany.company);
		if (selectedMarker) {
			selectedMarker.setIcon('https://maps.google.com/mapfiles/ms/micons/blue-dot.png');
		}
	}

	onSearchTermChanged(term: string) {
		this.searchTerm = term;
		this.currentPage = 1;
		this.getSearchResults();
	}

	onLocationChanged(location: string) {
		this.location = location;
		this.currentPage = 1;
		this.getSearchResults();
	}

	navigateToMicroSite() {
		this.router.navigate(['microsite', this.selectedCompany.micrositeID], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
		});
	}

	navigateToDirectoryHome() {
		if (this.directory) {
			this.router.navigate(['home'], {
				queryParams: { directory: this.directory },
			});
		}
	}

	navigateListView() {
		this.router.navigate(['search-list-view'], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.location },
		});
	}

	getUserLocation(): Promise<{ lat: number; lng: number }> {
		return new Promise((resolve, reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						resolve({
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						});
					},
					(error) => {
						reject(error);
					}
				);
			} else {
				reject('Geolocation is not supported by this browser.');
			}
		});
	}

	updateMarkers() {
		// Clear all existing markers from the map
		this.markers.forEach((marker) => marker.setMap(null));
		this.markers = []; // Reset markers array

		// Add new markers for companies on the current page
		this.companies.forEach((company) => {
			const marker = new google.maps.Marker({
				position: new google.maps.LatLng(company.latitude, company.longitude),
				map: this.map,
				title: company.company,
				icon: 'https://maps.google.com/mapfiles/ms/micons/red-dot.png',
			});

			// Add event listener to show info window on marker click
			marker.addListener('click', () => {
				this.infowindow.setContent(company.company);
				this.infowindow.open(this.map, marker);
			});

			this.markers.push(marker);
		});
	}

	sortCompanies(option: string) {
		this.sortOption = option;
		let companiesToSort = [...this.fullCompanies];

		switch (this.sortOption) {
			case 'bestMatch':
				companiesToSort.sort((a, b) => a.companySortOrder - b.companySortOrder);
				break;
			case 'aToZ':
				companiesToSort.sort((a, b) => a.company.localeCompare(b.company));
				break;
			case 'distance':
				this.getUserLocation()
					.then((userLocation) => {
						if (!userLocation.lat || !userLocation.lng) {
							console.error('Invalid user location:', userLocation);
							return;
						}

						companiesToSort.forEach((company) => {
							if (!isNaN(company.latitude) && !isNaN(company.longitude)) {
								const companyLocation = new google.maps.LatLng(company.latitude, company.longitude);
								const userLatLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
								company['distance'] = google.maps.geometry.spherical.computeDistanceBetween(
									userLatLng,
									companyLocation
								);
							} else {
								console.error('Invalid company location:', company);
							}
						});

						companiesToSort.sort((a, b) => (a['distance'] || 0) - (b['distance'] || 0));
						this.companies = companiesToSort;
						this.paginateCompanies();
						// Ensure the map is updated after sorting
						this.initMap();
					})
					.catch((error) => {
						console.error('Error fetching user location:', error);
					});
				return;
			default:
				break;
		}

		this.companies = companiesToSort;
		this.paginateCompanies();
		this.initMap();
	}

	paginateCompanies() {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		this.companies = this.companies.slice(startIndex, endIndex);
		this.updateMarkers();
	}

	changePage(page: number) {
		this.currentPage = page;
		this.getSearchResults();
		this.paginateCompanies();
		this.initMap();
		if (typeof window !== 'undefined') {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}

	getFullUrl(url: string) {
		return url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
	}

	pageTitleContent = [
		{
			title: 'Map-View',
			backgroundImage: 'assets/img/page-title/page-title1.jpg',
		},
	];
}
