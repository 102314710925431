import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { elementAt } from 'rxjs';
import { AutoCompleteModel } from 'src/app/components/core/models/common/auto.complete.model';
import { DirectoryHomeModel } from 'src/app/components/core/models/directoryHome/directory.home.model';
import { LocationModel } from 'src/app/components/core/models/location/location.model';
import { PopularHeadingModel } from 'src/app/components/core/models/popularHeading/popular.heading.model';
import { AutoCompleteService } from 'src/app/components/core/services/auto-complete/auto-complete.service';
import { DirectoryHomeService } from 'src/app/components/core/services/directory-home/directory-home.service';

@Component({
	selector: 'app-homeone-banner',
	templateUrl: './homeone-banner.component.html',
	styleUrls: ['./homeone-banner.component.scss'],
})
export class HomeoneBannerComponent implements OnInit {
	directoryHomeData: DirectoryHomeModel = null;
	popularHeadings: PopularHeadingModel[] = [];
	autoList: AutoCompleteModel[] = [];

	@Input() directory: string = '';
	@Input() searchTerm: string = '';
	@Input() selectedLocation: string = 'Everywhere';

	locations: LocationModel[] = [];
	options: any[] = []; // Array for dropdown options
	config = {
		displayKey: 'name', // Property to be displayed in the dropdown
		search: true, // Enable search
	};

	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();
	@Output() locationChanged: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private directoryHomeService: DirectoryHomeService,
		private autoCompleteService: AutoCompleteService,
		private router: Router,
		private route: ActivatedRoute,
		private spinner: NgxSpinnerService
	) {}

	ngOnInit(): void {
		this.getHomeBannerData();
		this.route.queryParamMap.subscribe((queryParams) => {
			const querySearchTerm = queryParams.get('searchTerm');
			if (querySearchTerm) {
				this.searchTerm = querySearchTerm;
			}
			const queryLocation = queryParams.get('location');
			if (queryLocation) {
				this.selectedLocation = queryLocation;
				//console.log('loc2', this.selectedLocation);
			}
		});
	}

	navigateSearchList() {
		if (!this.selectedLocation) {
			this.selectedLocation = 'Everywhere';
		}
		this.router.navigate(['search-list-view'], {
			queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
		});
	}

	async getHomeBannerData() {
		try {
			this.spinner.show();
			this.directoryHomeData = await this.directoryHomeService.getDirectoryHomeResults(this.directory);
			//console.log(this.directoryHomeData);
			if (this.directoryHomeData) {
				let popularHeadingsFromServer = this.directoryHomeData[0].popularHeadings;
				//console.log('Popular Headings:', popularHeadingsFromServer);

				popularHeadingsFromServer.forEach((element) => {
					this.popularHeadings.push({
						label: element.label,
						searchTerm: element.searchTerm,
					});
				});

				let locationsFromServer = this.directoryHomeData[0].locations;
				locationsFromServer.forEach((element) => {
					this.locations.push({
						name: element.name,
						isDefault: element.isDefault,
					});
				});
				const defaultLocation = this.locations.find((location) => location.isDefault);
				if (defaultLocation && !this.selectedLocation) {
					this.selectedLocation = defaultLocation.name;
					this.locationChanged.emit(this.selectedLocation);
				}
				this.options = this.locations; // Set the options for the dropdown
				//console.log(this.locations);
			} else {
				console.error('Error: Directory home data is null or undefined');
			}
		} catch (error) {
			console.error('Error fetching category data:', error);
		} finally {
			this.spinner.hide();
		}
	}

	async getAutoSearchList() {
		try {
			this.spinner.show();
			console.log(this.searchTerm);
			let response = await this.autoCompleteService.getAutoCompleteResults(this.directory, this.searchTerm);
			response.forEach((element) => {
				this.autoList.push({
					searchTermMatch: element.searchTermMatch,
				});
			});
			console.log('List', this.autoList);
		} catch (error) {
		} finally {
			this.spinner.hide();
		}
	}

	onSearchTermInput(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		this.searchTerm = inputElement.value;

		// Only trigger auto-complete if the input has 2 or more characters
		if (this.searchTerm.length >= 2) {
			this.searchTermSelected.emit(this.searchTerm);
			this.autoList = [];
			this.getAutoSearchList();
		} else {
			this.autoList = [];
		}
	}

	onLocationChanged(event) {
		this.selectedLocation = event.value.name;
		//console.log('selected', this.selectedLocation);
		this.locationChanged.emit(this.selectedLocation);
	}

	setPopularSearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.navigateSearchList();
		// console.log(this.searchTerm, this.selectedLocation);
	}

	selectAutoCompleteItem(item: AutoCompleteModel) {
		this.searchTerm = item.searchTermMatch;
		this.searchTermSelected.emit(this.searchTerm);
		this.autoList = []; // Optionally clear the list after selection
		this.navigateSearchList();
	}
}
