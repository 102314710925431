import { Component, NgZone, OnInit } from '@angular/core';
import { AutoCompleteService } from '../../core/services/auto-complete/auto-complete.service';
import { CategoryService } from '../../core/services/category/category.service';
import { DirectoryHomeService } from '../../core/services/directory-home/directory-home.service';
import { HeaderFooterService } from '../../core/services/header-footer/header-footer.service';
import { LandingService } from '../../core/services/landing/landing.service';
import { SearchResultService } from '../../core/services/search-result/search-result.service';
import { LocationHomeService } from '../../core/services/location-home/location-home.service';
import { ActivatedRoute } from '@angular/router';
import { DirectoryHomeModel } from '../../core/models/directoryHome/directory.home.model';
import { FeaturedAdModel } from '../../core/models/featureAd/feature.ad.model';
import { DomSanitizer } from '@angular/platform-browser';
import { QuickLinkModel } from '../../core/models/quickLink/quick.link.model';
import { MobileAppSectionModel } from '../../core/models/mobileAppSection/mobile.app.section.model';
import { CityModel } from '../../core/models/city/city.model';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-home-demo-one',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeDemoOneComponent implements OnInit {
	response: DirectoryHomeModel;
	featureAds: FeaturedAdModel[] = [];
	categories: any[] = [];
	searchTerm: string = '';
	directory: string = '';
	cities: CityModel[] = [];

	quickLinks: QuickLinkModel[] = [];
	mobileAppSection: MobileAppSectionModel[] = [];

	constructor(
		private directoryHomeService: DirectoryHomeService,
		private headerFooterService: HeaderFooterService,
		private sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private ngZone: NgZone,
		private meta: Meta,
		private title: Title
	) {
		// Meta tags for SEO optimization
		this.meta.addTags([
			{
				name: 'description',
				content: 'Discover local businesses, services on Arvig Pages, and more on our SEO-friendly platform.',
			},
			{ name: 'author', content: 'ArvigPages' },
			{ name: 'keywords', content: 'advertising, directory, business listings, local search, Angular SEO,Arvig pages' },
			{ property: 'og:url', content: 'https://www.arvigpages.com/#/' },
		]);
		this.setTitle('Home - Discover Local Services | Arvig Pages');
		this.meta.addTag({ rel: 'canonical', href: 'https://www.arvigpages.com/#/home' });
	}

	public setTitle(newTitle: string) {
		this.title.setTitle(newTitle);
	}

	ngOnInit(): void {
		this.route.queryParamMap.subscribe((queryParams) => {
			this.directory = queryParams.get('directory');
		});

		this.loadData();
		this.scrollToTop();
		this.updateCanonicalUrl();
	}
	updateCanonicalUrl() {
		const canonicalUrl = this.directory
			? `https://www.arvigpages.com/home?directory=${this.directory}`
			: 'https://www.arvigpages.com/home';
		this.meta.addTag({ rel: 'canonical', href: canonicalUrl });
	}

	scrollToTop() {
		this.ngZone.runOutsideAngular(() => {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					window.scrollTo(0, 0);
				}
			}, 0);
		});
	}

	async loadData() {
		try {
			this.response = await this.directoryHomeService.getDirectoryHomeResults(this.directory);
			//console.log('Directory Home Results:', this.response);

			let featureAdsFromSever = this.response[0].featuredAds;
			//console.log('FeatureAds', featureAdsFromSever);

			for (let index = 0; index < featureAdsFromSever.length; index++) {
				let ad: FeaturedAdModel = new FeaturedAdModel();
				ad.imageURL = this.sanitizer.bypassSecurityTrustUrl(
					'http://' + featureAdsFromSever[index].featuredAdImage
				) as string;

				ad.adClickURL = 'http://' + featureAdsFromSever[index].adClickURL;
				ad.company = featureAdsFromSever[index].company;
				ad.featuredAdImage = featureAdsFromSever[index].featuredAdImage;

				this.featureAds.push(ad);
			}

			this.response[0].categories.forEach((element, index) => {
				this.categories.push({
					title: element.title,
					searchTerm: element.searchTerm,
					categoryIcon: 'http://' + element.categoryIcon,
				});
			});

			this.categories.push({
				categoryIcon: 'assets/fonts/more-categories.svg',
				title: 'More Categories',
				searchTerm: 'More Categories',
			});

			let quickLinksFromServer = this.response[0].quickLinks;
			quickLinksFromServer.forEach((element) => {
				this.quickLinks.push({
					groupName: element.groupName,
					title: element.title,
					url: this.getFullUrl(element.url),
				});
			});

			let mobileAppSectionFromServer = this.response[0].mobileAppSection;
			mobileAppSectionFromServer.forEach((element) => {
				this.mobileAppSection.push({
					mobileAppImage: element.mobileAppImage,
					appStoreUrl: element.appStoreUrl,
					googlePlayStoreUrl: element.googlePlayStoreUrl,
				});
			});

			let citiesFromServer = this.response[0].cities;
			citiesFromServer.forEach((element) => {
				this.cities.push({
					cityName: element.cityName,
					locationHomeUrl: element.locationHomeUrl,
				});
			});

			const headerFooter = await this.headerFooterService.getHeaderAndFooter(this.directory);
			//console.log('Header and Footer:', headerFooter);
		} catch (error) {
			console.error('Error loading data:', error);
		}
	}

	getFullUrl(url: string): string {
		if (!url) {
			return ''; // Return empty string or handle missing URLs as needed
		}
		return url.startsWith('http') ? url : 'http://' + url;
	}
}
