import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DirectoryHomeService } from '../../core/services/directory-home/directory-home.service';
import { CategoryModel, CategoryTag } from '../../core/models/category/category.model';
import { CategoryService } from '../../core/services/category/category.service';
import { DirectoryHomeModel } from '../../core/models/directoryHome/directory.home.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-category',
	templateUrl: './category.component.html',
	styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
	@Input() directory: string = '';
	@Input() categories: any[] = [];
	searchTerm: string = '';
	@Input() selectedLocation: string = 'Everywhere';

	@Output() searchTermSelected: EventEmitter<string> = new EventEmitter<string>();

	constructor(private router: Router) {}

	ngOnInit(): void {
		//console.log(this.categories);
	}

	navigateSearchList() {
		if (this.searchTerm === 'More Categories') {
			this.router.navigate(['/categories'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
			});
		} else {
			this.router.navigate(['search-list-view'], {
				queryParams: { searchTerm: this.searchTerm, directory: this.directory, location: this.selectedLocation },
			});
		}
	}

	setCategorySearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
		this.searchTermSelected.emit(this.searchTerm);
		this.navigateSearchList();
	}
}
